import "react-app-polyfill/ie11";
import "@thalesrc/js-utils/array/proto/uniquify";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./template/ScrollToTop";
import { locale } from "primereact/api";
import "./i18nextInit";
import { addLocale } from "primereact/api";
import { trLocale } from "./utils/trLocale";


addLocale("tr", trLocale);
locale(localStorage.getItem("locale"));

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
