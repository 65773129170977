/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useMemo, useState } from "react";
import { useStateStorage } from "../hooks/use-state-storage";

export const AppContext = createContext();

export function AppContextProvider({ children }) {
  // const [store, setStore] = useState({
  //   mode: "light",
  // });
  const [theme, setTheme] = useStateStorage("theme");
  const [language, setLanguage] = useStateStorage("language");
  const [selectedFactory, setSelectedFactory, removeSelectedFactory] = useStateStorage("selectedFactory");
  const [selectedRoles, setSelectedRoles, removeSelectedRoles] = useStateStorage("selectedRoles");

  const [actions, setActions] = useState({
    changeTheme: (mode) => setTheme(mode),
  });

  const context = useMemo(
    () => ({ actions, setActions, theme, setTheme, language, setLanguage, selectedFactory, setSelectedFactory, removeSelectedFactory, selectedRoles, setSelectedRoles, removeSelectedRoles }),
    [actions, setActions, theme, setTheme, language, setLanguage, selectedFactory, setSelectedFactory, removeSelectedFactory, selectedRoles, setSelectedRoles, removeSelectedRoles]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
