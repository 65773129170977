import React from "react";
import PrimeReact from "primereact/api";
import { Switch } from "react-router";

import 'reactjs-popup/dist/index.css';
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import "leaflet/dist/leaflet.css";

//Context
import { LoadingContextProvider } from "./context/Loading";
import { ToasterContextProvider } from "./context/ToasterContext";
import { UserContextProvider } from "./context/UserContext";
import { AppContextProvider } from "./context/AppContext";
import Loader from "./components/Loader";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import pageURL from "./utils/pageUrls";
import { QueryClient, QueryClientProvider } from "react-query";
import GlobalRoute from "./components/GlobalRoute";

//Pages
const TheLayout = React.lazy(() => import("./template/TheLayout"));
const Login = React.lazy(() => import("./pages/Login"));
const CordlynxLogin = React.lazy(() => import("./pages/CordlynxLogin"));
const Download = React.lazy(() => import("./pages/DownloadManagement"));
const DownloadIos = React.lazy(() => import("./pages/DownloadManagement/indexIos"));

const queryClient = new QueryClient();

const App = () => {
  PrimeReact.ripple = true;

  return (
    <LoadingContextProvider>
      <AppContextProvider>
        <ToasterContextProvider>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <React.Suspense fallback={<Loader isLoaderShow={true}></Loader>}>
                <Switch>
                  <PublicRoute component={Login} path={pageURL.login} exact />
                  <PublicRoute component={CordlynxLogin} path={'/cordlynx-login'} exact />
                  <GlobalRoute component={Download} path={pageURL.downloadManagement} exact />
                  <GlobalRoute component={DownloadIos} path={pageURL.downloadManagementIos} exact />
                  <PrivateRoute component={TheLayout} path="*" exact />
                </Switch>
              </React.Suspense>
            </UserContextProvider>
          </QueryClientProvider>
        </ToasterContextProvider>
      </AppContextProvider>
    </LoadingContextProvider>
  );
};

export default App;
